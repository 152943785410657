import React from "react"
import { Link } from "gatsby"
import {
  Row,
  Col,
  Container, 
  Breadcrumb,
  Button,
  Carousel,
  Accordion,
  Card
} from "react-bootstrap"

import Layout from "../components/layout"
import Seo from "../components/seo"

import LuteinComplexMobile from "./../images/LTZ_MOBILE.jpg";
import LuteinComplexTablet from "./../images/LTZ_TABLET.jpg";
import LuteinComplexDesktop from "./../images/LTZ-DESKTOP.jpg";
import LuteinComplexXL from "./../images/LTZ_DESKTOP-XL.jpg";
import Feature1 from "./../images/LUTEIN-EYE-LOUPE.jpg"
import Feature2 from "./../images/LUTEIN-BILBERRY-MARIGOLDS.jpg"
import Feature3 from "./../images/LUTEIN-EYE-ANATOMY.jpg"
import Feature4 from "./../images/LICAPS-GUARANTEE.jpg"
import Feature5 from "./../images/lutein-table-mobile.svg"
import Feature5xl from "./../images/lutein-table-xl.svg"
import Testimonial1 from "./../images/veska-stoyanova.jpg"
import Testimonial2 from "./../images/dr-atanasova.jpg"
import Testimonial3 from "./../images/Stela-Vulcheva-lutein-complex.jpg";
import Testimonial4 from "./../images/maria-angelova-lutein-complex.jpg";


const LuteinPage = () => (
  <Layout backButton={true} pageInfo={{ pageName: "lutein-complex" }}>
    <Seo title="Lutein Complex Plus - Продукт номер 1 в грижата за очите" />
    <Container className="p-0">
      <Row>
        <Col className="d-none d-md-block">
          <Breadcrumb>
            <li className="breadcrumb-item">
              <Link to="/">НАЧАЛНА СТРАНИЦА</Link>
            </li>
            <Breadcrumb.Item active>LUTEIN COMPLEX PLUS</Breadcrumb.Item>
          </Breadcrumb>
        </Col>
      </Row>
      <Row>
        <Col sm={12}>
          <picture>
            <source srcSet={LuteinComplexXL} media="(min-width: 1200px)" />
            <source srcSet={LuteinComplexDesktop} media="(min-width: 992px)" />
            <source srcSet={LuteinComplexTablet} media="(min-width: 767px)" />
            <source srcSet={LuteinComplexMobile} media="(min-width: 370px)" />
            <img
              srcSet={LuteinComplexMobile}
              alt="Lutein Complex Plus"
              className="w-100 fluid rounded mb-4"
            />
          </picture>
        </Col>
        {/* <Col xs={6} className="d-none d-lg-block large-heading">
          <h5 className="text-uppercase font-light letter-space-wide ">
            Lutein Complex Plus
          </h5>
          <h1 className="display-4 font-light">
            Номер 1 в<br /> грижата за очите*
          </h1>
        </Col> */}
      </Row>
      <Row className="mb-5 product-container">
        <Col sm={12}>
          <h2 className="display-5 font-light text-center announcement">
            <span className="purple font-weight-normal ">
              ZeaForce<sup>®</sup>
            </span>{" "}
            e новото име на любимия ви продукт за очи „Лутеин Комплекс Плюс“.
            Съставът на продукта остава непроменен.{" "}
            <span className="purple font-weight-normal">
              ZeaForce<sup>®</sup>
            </span>{" "}
            ще е на пазара от 1 януари 2025 г.
          </h2>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        {/* <Col sm={12} className="d-lg-none">
          <h4 className="text-uppercase mb-3 letter-space-wide">
            Lutein Complex Plus
          </h4>
          <h1 className="display-5 font-light mb-4 large-heading-mobile">
            Номер 1 в<br /> грижата за очите*
          </h1>
        </Col> */}
        <Col sm={12} md={6} className="mb-4">
          <h5 className="subhead font-light">
            Капсули с течно съдържание, съхранили в себе си оптимални дози
            лутеин, зеаксантин, екстракт от черна боровинка и цинк —
            най-полезните вещества за очите.
          </h5>
        </Col>
        <Col sm={12} md={6}>
          <Col className="mb-4 p-0">
            <div>
              <a
                href="https://zeaforce.com"
                target="blank"
                rel="nofollow"
                className="text-decoration-none"
              >
                <Button
                  variant="primary"
                  className="zeaforce-button mx-auto d-block"
                >
                  Към новият сайт на <strong>ZeaForce</strong>
                </Button>
              </a>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <p>Продава се в аптеките в цялата страна</p>
            </div>
          </Col>
          <Col className="mb-4 p-0">
            <div className="pharma-label mx-auto d-block">
              <ul>
                <li>без разтворители</li>
                <li>без консерванти</li>
                <li>без глутен</li>
                <li>без захар</li>
                <li>без ГМО</li>
              </ul>
            </div>
          </Col>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Поглед върху зрението</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature1}
            alt="Поглед върху зрението"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Грижим се за очите си само, когато възникне проблем с тях, а като
            едно от най-важните ни сетива, не бива да ги пренебрегваме. Голяма
            част от проблемите на очите, свързани с неподходяща диета или с
            напредването на възрастта, могат да бъдат избегнати. За целта е
            нужно да се вземат правилните мерки отрано.
          </p>
          <p>
            Вече над 15 години Лутеин Комплекс се грижи за очите на хиляди хора.
            Специално подбраните му съставки имат доказан благоприятен ефект
            върху здравето на очите, а иновативната технология ускорява
            многократно усвояването му от организма.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Оптимална грижа за Вашите очи</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature2}
            alt="Оптимална грижа за Вашите очи"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6}>
          <p>
            Активните съставки в Лутеин Комплекс са жизнено важни за поддържане
            на здравето на очите според редица изследвания.**
          </p>
          <p>
            Лутеин и зеаксантин са най-важните антиоксиданти, намиращи се в
            окото, които неутрализират вредите, причинени от свободните
            радикали. Лутеин и Зеаксантин са каротеноидите, отговорни за
            оцветяването на окото. Зеаксантинът е компонентът, който доминира в
            самия център на макулата на окото, а лутеинът се среща в по-високи
            концентрации в ретината.
          </p>
          <p>
            Екстрактът от черна боровинка съдейства за укрепване на очните
            капиляри, регенерацията на зрителните пигменти и подобрява
            циркулаторната дейност.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Превантивна мярка и цялостно решение</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6} className="order-md-1">
          <img
            srcSet={Feature3}
            alt="Превантивна мярка и цялостно решение"
            className="w-100 fluid rounded mb-4"
          />
        </Col>
        <Col md={6} className="order-md-0">
          <p>
            Приемът на Лутеин Комплекс е превантивна мярка за основните
            нарушения на зрението, появяващи се с напредване на възрастта.
          </p>
          <p>
            Установена е пряка връзка между приема на лутеин и зеаксантин, и
            превенцията от появата на катаракта и развитието на
            възрастово-свързана макулна дегенерация.** Възрастово-свързаната
            макулна дегенерация причинява прогресивна загуба на централното
            зрение.
          </p>
          <p>
            Катарактата води до увеличаване на плътността на лещата, а оттам до
            намаляване на нейната прозрачност и до слепота.
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Гаранция за качество и сигурност</h2>
        </Col>
      </Row>
      <Row className="product-container mb-5">
        <Col md={6}>
          <img
            srcSet={Feature4}
            alt="Гаранция за качество и сигурност"
            className="w-100 fluid rounded mb-4 "
          />
        </Col>
        <Col md={6}>
          <p>
            Капсулите Лутеин Комплекс са произведени в заводите на CAPSUGEL в
            Колмар - Франция, по патентованата технология за влагане на течни
            растителни екстракти в твърди капсули.
          </p>
          <p>
            При тази технология се елиминира термичната обработка на веществата,
            което позволява да се запазят полезните им свойства и гарантира
            максимално бързо и пълно усвояване от организма. В сравнение с
            твърдите форми, като таблетките, съставките, намиращи се в капсулите
            Лутеин Комплекс се усвояват много бързо и по-ефективно, защото са
            предварително разтворени. Те са напълно натурални и не съдържат
            разтворители, консерванти, глутен, захар и ГМО.
          </p>
          <p>
            Прочетете повече в раздел <Link to="/technology">“Технология”</Link>
            .
          </p>
        </Col>
      </Row>
      <Row className="mb-3">
        <Col>
          <h2 className="text-center">Клинично потвърдена ефективност</h2>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col>
          <picture>
            <source srcSet={Feature5xl} media="(min-width: 760px)" />
            <source srcSet={Feature5} media="(min-width: 370px)" />
            <img
              srcSet={Feature5}
              alt="Клинично потвърдена ефективност"
              className="mb-4 mw-100 mx-auto d-block"
            />
          </picture>
        </Col>
      </Row>
      <Row className="product-container mb-2">
        <Col>
          <h5 className="section-heading">
            Мнения на специалисти
            <br />и потребители
          </h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Carousel
            indicators={false}
            fade={false}
            interval={4500}
            className="shadow-1 mx-auto testimonial-container"
          >
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial1}
                  alt="Мнение на потребител"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">
                      Най-добрата комбинация за очи
                    </h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Аз съм на 80 години и от десет години пия непрекъснато
                    ЛУТЕИН КОМПЛЕКС ПЛЮС. Започнах, когато доктора ми откри
                    зачатъци на перде и много се уплаших. Той ми каза да пия
                    ЛУТЕИН КОМПЛЕКС ПЛЮС всеки ден по 1 капсула.
                    <br />
                    <br />
                    И сега да се похваля, перде нямам, не нося очила, чета си
                    програмата без очила. Имам две дъщери и трима внуци. Те нали
                    с компютри работят, сега и те пият ЛУТЕИН КОМПЛЕКС ПЛЮС и им
                    помага. Много съм щастлива, че успях да помогна и на себе
                    си, и на тях.
                    <br />
                    <br />
                    Веска Стоянова, 85 г., <br />
                    Велинград
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial2}
                  alt="Мнение на потребител"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">
                      Помага срещу катаракта (перде)
                    </h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Препоръчвам ЛУТЕИН КОМПЛЕКС ПЛЮС, тъй като той съдържа
                    комплекс от най-добрите съставки и екстракти за грижата на
                    очите във високи концентрации. Проучванията сочат, че те
                    намаляват риска от катаракта (перде на окото) и укрепват
                    кръвоносните съдове на очите. Приемайте ЛУТЕИН КОМПЛЕКС ПЛЮС
                    по 1 капсула дневно поне 3 месеца.
                    <br />
                    На лицата в риск от развитие на очни заболявания, както и на
                    страдащите от такива, препоръчвам да провеждат ежегодно поне
                    по един тримесечен терапевтичен курс с прием на ЛУТЕИН
                    КОМПЛЕКС ПЛЮС.
                    <br />
                    <br />
                    Д-р Василка Атанасова, офталмолог, <br />
                    Пловдив
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial3}
                  alt="Мнение на потребител"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">
                      Лутеин Комплекс Плюс помага за запазване на зрението ми
                    </h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Нося очила за късогледство от 10 годишна възраст и често
                    посещавам офталмолог. От доста години (поне 12) пия Лутеин
                    Комплекс Плюс, който ми беше препоръчан от моя лекар. Вземам
                    по 1 капсула на ден и очите ми са в добро състояние за
                    възрастта ми – аз съм на 75 години. Ползвам компютър,
                    таблет, телефон и чета книги. А иначе съм късогледа, но в
                    сравнение с младите ми години диоптрите са наполовина
                    по-малки. Твърдо съм убедена, че Лутеин Комплекс Плюс помага
                    за запазване на зрението ми. Дори смея да мисля, че с негова
                    помощ отдалечих много появата на катаракта. Препоръчвам го
                    уверено, защото съм го изпитала от личен опит.
                    <br />
                    <br />
                    Стела Вълчева, 75 г., <br />
                    Долни Дъбник
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
            <Carousel.Item>
              <Card className="testimonial">
                <Card.Img
                  className="d-block mx-auto"
                  variant="top"
                  src={Testimonial4}
                  alt="Мнение на потребител"
                />
                <Card.Body>
                  <Card.Title>
                    <h5 className="font-light">
                      За всички, които знаят какво е да не виждаш добре
                    </h5>
                  </Card.Title>
                  <Card.Text className="h6-small">
                    Здравейте! За Лутеин Комплекс Плюс научих преди години от
                    моята сега вече покойна майка. Тя ме убеждаваше да си го
                    купя, защото беше много доволна от него – страдаше от
                    катаракта, глаукома и макулна дегенерация. Накрая тя ми го
                    взе, но аз все не стигах да го започна. Една вечер, след
                    като дълго време лявото ми око беше замъглено (от няколко
                    месеца беше така – надписите на телевизора бяха двойни, а с
                    дясното всичко беше наред), си седях пред телевизора и се
                    ядосвах, че ми е мъгляво и даже го усещах като запрашено.
                    Станах, намерих кутията Лутеин Комплекс Плюс и изпих една
                    капсула. Тук вече и аз се чудя все още... След няколко
                    минути усетих облекчение в паренето и „запрашеното“ усещане.
                    Погледнах с лявото око надписите, бяха чисти. Не беше за
                    вярване... Изпих си цялата опаковка, но не продължих повече.
                    Мина около година, а дори и повече, когато отново ми се
                    замъгли окото. Двойният образ. Същата картинка. Този път
                    сама си купих Лутеин Комплекс Плюс и се повтори същото.
                    Звъннах на моя братовчедка, която е химик и ѝ разказах.
                    Попитах я дали не си въобразявам. Отговорът ѝ беше: „Не, не
                    си въобразяваш. Тези вещества действат благотворно на
                    очите!“ От тогава си правя по два терапевтични курса на
                    прием на Лутеин Комплекс Плюс годишно, всеки с
                    продължителност 3 месеца, и нямам никакви оплаквания.
                    Препоръчвам го на всички, които знаят какво е да не виждаш
                    добре. Ще останат приятно изненадани.
                    <br />
                    <br />
                    Мария Ангелова, 66 г., <br />
                    Шумен
                  </Card.Text>
                </Card.Body>
              </Card>
            </Carousel.Item>
          </Carousel>
        </Col>
      </Row>
      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Повече за Лутеин Комплекс Плюс</h5>
        </Col>
      </Row>
      <Row className="mb-5 product-container">
        <Col>
          <Accordion defaultActiveKey="0" className="acc-style">
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="0"
                className="pointer"
              >
                Състав
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="0">
                <Card.Body>
                  <p>
                    <strong>Лутеин</strong> (Lutein) 33.0 mg
                  </p>
                  <p>
                    <strong>Зеаксантин</strong> (Zeaxanthin) 8.25 mg
                  </p>
                  <p>
                    <strong>Екстракт от черна боровинка</strong> (Extr.
                    Vaccinium myrtillus, 25% anthocianidines) 30.0 mg{" "}
                  </p>
                  <p>
                    <strong>Витамин С</strong> (Ascorbic acid) 66.0 mg
                  </p>
                  <p>
                    <strong>Витамин Е</strong> ( D – Alpha tocopherol natural)
                    16.5 mg α-TE{" "}
                  </p>
                  <p>
                    <strong>Цинк</strong> (Zinc Sulfate) 15.0 mg
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="1"
                className="pointer"
              >
                Прием
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="1">
                <Card.Body>
                  <p>
                    Лутеин Комплекс Плюс се приема по 1 капсула дневно след
                    храна, не по-малко от 3 месеца.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="2"
                className="pointer"
              >
                Противопоказания
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="2">
                <Card.Body>
                  <p>
                    Не са установени. Не се препоръчва при лица с алергия към
                    рибни продукти. Лутеин Комплекс е хранителна добавка и не
                    предизвиква дразнене и свръхчувствителност и може да се
                    приема продължително време. Лутеин Комплекс може да се
                    приема самостоятелно или в комбинация с предписани от
                    лекуващия лекар медикаменти.
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
            <Card>
              <Accordion.Toggle
                as={Card.Header}
                eventKey="3"
                className="pointer"
              >
                Действие
              </Accordion.Toggle>
              <Accordion.Collapse eventKey="3">
                <Card.Body>
                  <p>
                    Лутеин Комплекс е комбинация от високоефективни
                    антиоксиданти. Витамин C, витамин Е и цинк допринасят за
                    защитата на клетките от оксидативен стрес. Повишената
                    концентрация на биофлавоноидите - лутеин и зеаксантин в
                    очите, подкрепя здравето им при умора и стареене. Екстрактът
                    от боровинки подпомага, адаптивните функции и възможности на
                    окото към светлина и тъмнина, и съдейства за поддържане на
                    зрителната острота. Благоприятно повлиява функциите на
                    очите, при въздействие на околната среда - UV излъчване на
                    слънцето, изкуствено осветление и др. Цинкът допринася за
                    поддържането на нормалното зрение.
                  </p>
                  <p>
                    <a
                      href="https://vitaherb.bg/leaflets/LUTEIN-COMPLEX-PLUS-LISTOVKA.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Листовка на продукта
                    </a>
                  </p>
                  <p>
                    <a
                      href="https://vitaherb.bg/leaflets/Lutein-Complex-Plus-Quality-Certificate.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Сертификат за качество
                    </a>
                  </p>
                </Card.Body>
              </Accordion.Collapse>
            </Card>
          </Accordion>
        </Col>
      </Row>
      <Row className="mb-2 product-container">
        <Col>
          <h5 className="section-heading">Изследвания и източници</h5>
        </Col>
      </Row>
      <Row className="mb-4 product-container">
        <Col className="h6-small">
          <p>
            * Най-продаван продукт в категория „Витамини и добавки за очи“ за
            2020 г. по данни на IQVIA.
          </p>
          <p>
            ** Richer S, Stiles W, Statkute L, et al. Lutein Antioxidant
            Supplementation Trial. Optometry 2004;75:216-230; Seddon JM, Ajani
            UA, Sperduto RD, et al. JAMA 1994; 272:1413-1420;
            <br /> Chasen - Taber L, Willet WC, Seddon JM, et al. Am J Clin Nutr
            1999;70:509-516.
            <br /> Invest. Ophthalmol. Vis. Sci. June 2006 vol. 47 no. 6
            2329-2335 Catharine R. Gale, Nigel F. Hall, David I. W. Phillips and
            Christopher N. Martyn, Lutein and Zeaxanthin Status and Risk of
            Age-Related Macular Degeneration Invest. <br />
            Ophthalmol. Vis. Sci. June 2003 vol. 44 no. 6 2461-2465
            <br /> D M Snodderly, Evidence for protection against age-related
            macular degeneration by carotenoids and antioxidant vitamins, Am J
            Clin Nutr December 1995 vol. 62 no. 6 1448S-1461S
          </p>
        </Col>
      </Row>
    </Container>
  </Layout>
);

export default LuteinPage
