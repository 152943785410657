import { Link } from "gatsby"
import React from "react"
import { Container, Row, Col, Image } from "react-bootstrap"
import CookieConsent from "react-cookie-consent"

import Logo from "./../images/Logo-Mobile.svg"

const Footer = ({ enSite }) => {

const bgText = {
  url: "/",
  logoAlt: "Вита Херб",
  backAlt: "Назад",
  lang: "ЕЗИК",
  products: "ПРОДУКТИ",
  herbalProd: "БИЛКОВИ ПРОДУКТИ O’YES VITA",
  lutein: "/lutein-complex",
  hepatofelin: "/hepatofelin",
  stavien: "/stavien",
  proplus: "/prostatizal-plus",
  psorplus: "/psoriazal-plus",
  laksiprokt: {
    name: "Лаксипрокт",
    link: "/laksiprokt",
  },
  liporedukt: {
    name: "Липоредукт",
    link: "/liporedukt",
  },
  menopauzal: {
    name: "Менопаузал",
    link: "/menopauzal",
  },
  miomal: {
    name: "Миомал",
    link: "/miomal",
  },
  nevroherb: {
    name: "Неврохерб",
    link: "/nevroherb",
  },
  pielonefral: {
    name: "Пиелонефрал",
    link: "/pielonefral",
  },
  prostatizal: {
    name: "Простатизал",
    link: "/prostatizal",
  },
  psoriazal: {
    name: "Псориазал",
    link: "/psoriazal",
  },
  veniprotekt: {
    name: "Венипротект",
    link: "/veniprotekt",
  },
  articles: {
    text: "Статии",
    link: "/articles",
  },
  technology: {
    text: "Технология",
    link: "/technology",
  },
  privacy: {
    text: "Защита на личните данни",
    link: "/privacy",
  },
  about: {
    text: "За Вита Херб",
    link: "/about",
  },
  contact: {
    text: "Контакти",
    link: "/contact",
  },
  sitemap: {
    text: "Карта на сайта",
    link: "/site-map",
  },
  rights: "© Вита Херб ИНТ. Всички права запазени.",
  tel1: {
    text: "02 944 26 27",
    link: "tel:029442627",
  },
  tel2: {
    text: "0876 18 1000",
    link: "tel:0876181000",
  },
  cookieDecline: "Отказвам",
  cookieAccept: "Приемам",
  cookieText: {
    p: "Този сайт използва „бисквитки“, за анализ на входящия трафик. Прилага се и политика за защита на личните данни, с която може да се запознаете ",
    here: "тук",
  },
}

const enText = {
  url: "/en",
  logoAlt: "Vita Herb",
  backAlt: "back",
  lang: "LANGUAGE",
  products: "PRODUCTS",
  herbalProd: "HERBAL PRODUCTS O’YES VITA",
  lutein: "/en/lutein-complex",
  hepatofelin: "/en/hepatofelin",
  stavien: "/en/stavien",
  proplus: "/en/prostatizal-plus",
  psorplus: "/en/psoriazal-plus",
  laksiprokt: {
    name: "Laxiproct",
    link: "/en/laksiprokt",
  },
  liporedukt: {
    name: "Liporeduct",
    link: "/en/liporedukt",
  },
  menopauzal: {
    name: "Menopauzal",
    link: "/en/menopauzal",
  },
  miomal: {
    name: "Miomal",
    link: "/en/miomal",
  },
  nevroherb: {
    name: "Nevroherb",
    link: "/en/nevroherb",
  },
  pielonefral: {
    name: "Pielonefral",
    link: "/en/pielonefral",
  },
  prostatizal: {
    name: "Prostatizal",
    link: "/en/prostatizal",
  },
  psoriazal: {
    name: "Psoriazal",
    link: "/en/psoriazal",
  },
  veniprotekt: {
    name: "Veniprotect",
    link: "/en/veniprotekt",
  },
  articles: {
    text: "Articles",
    link: "/en/articles",
  },
  technology: {
    text: "Technology",
    link: "/en/technology",
  },
  privacy: {
    text: "Data privacy",
    link: "/en/privacy",
  },
  about: {
    text: "About Vita Herb",
    link: "/en/about",
  },
  contact: {
    text: "Contacts",
    link: "/en/contact",
  },
  sitemap: {
    text: "Site map",
    link: "/en/site-map",
  },
  rights: "© Vita Herb INT. All rights reserved.",
  tel1: {
    text: "+359 2 944 2627",
    link: "tel:+35929442627",
  },
  tel2: {
    text: "+359 876 18 1000",
    link: "tel:+359876181000",
  },
  cookieDecline: "Decline",
  cookieAccept: "Accept",
  cookieText: {
    p: "This website uses cookies to analyze the incoming traffic. Data protection policy is applied. You can learn more about it ",
    here: "here",
  },
}

  let currentLang
  enSite ? (currentLang = enText) : (currentLang = bgText)

    return (
      <Container fluid className="light-bg pt-4">
        <Container className="p-0">
          <Row
            noGutters
            className="flex-row flex-wrap mb-4 justify-content-start"
          >
            <Col className="d-flex flex-row flex-wrap col-6">
              <Col className="col-lg-6 footer-style pl-0 mb-5">
                <ul>
                  <li>
                    <Link to={currentLang.lutein}>Lutein Complex Plus</Link>
                  </li>

                  <li>
                    <Link to={currentLang.hepatofelin}>Hepatofelin</Link>
                  </li>

                  <li>
                    <Link to={currentLang.stavien}>Stavien</Link>
                  </li>

                  <li>
                    <Link to={currentLang.proplus}>Prostatizal Plus</Link>
                  </li>

                  <li>
                    <Link to={currentLang.psorplus}>Psoriazal Plus</Link>
                  </li>
                </ul>
              </Col>
              <Col className="col-lg-6 footer-style pl-0">
                <ul>
                  <li>
                    <Link to={currentLang.laksiprokt.link}>
                      {currentLang.laksiprokt.name}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.liporedukt.link}>
                      {currentLang.liporedukt.name}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.menopauzal.link}>
                      {currentLang.menopauzal.name}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.miomal.link}>
                      {currentLang.miomal.name}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.veniprotekt.link}>
                      {currentLang.veniprotekt.name}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.nevroherb.link}>
                      {currentLang.nevroherb.name}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.pielonefral.link}>
                      {currentLang.pielonefral.name}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.prostatizal.link}>
                      {currentLang.prostatizal.name}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.psoriazal.link}>
                      {currentLang.psoriazal.name}
                    </Link>
                  </li>
                </ul>
              </Col>
            </Col>
            <Col className="d-flex flex-row flex-wrap col-6 align-items-start justify-content-start">
              <Col className="col-lg-6 footer-style pl-0 ">
                <ul>
                  {!enSite && (
                    <li>
                      <Link to="/articles">Статии, свързани със здравето</Link>
                    </li>
                  )}

                  <li>
                    <Link to={currentLang.technology.link}>
                      {currentLang.technology.text}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.privacy.link}>
                      {currentLang.privacy.text}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.sitemap.link}>
                      {currentLang.sitemap.text}
                    </Link>
                  </li>
                </ul>
              </Col>
              <Col className="col-lg-6 footer-style pl-0">
                <ul>
                  <li>
                    <Link to={currentLang.about.link}>
                      {currentLang.about.text}
                    </Link>
                  </li>

                  <li>
                    <Link to={currentLang.contact.link}>
                      {currentLang.contact.text}
                    </Link>
                  </li>

                  <li>
                    <a href={currentLang.tel1.link}>{currentLang.tel1.text}</a>
                  </li>

                  <li>
                    <a href={currentLang.tel2.link}>{currentLang.tel2.text}</a>
                  </li>

                  <li>
                    <a
                      href="https://www.facebook.com/vitaherb/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Facebook
                    </a>
                  </li>
                </ul>
              </Col>
            </Col>
          </Row>
          <Row className="justify-content-center">
            <Col className="col-12 brand">
              <footer className="">
                <Link to={currentLang.url}>
                  <Image
                    className="mx-auto d-block mb-4"
                    src={Logo}
                    alt={currentLang.logoAlt}
                  />
                </Link>
                <span className="d-block h6-small mb-4">
                  {currentLang.rights}
                </span>
              </footer>
            </Col>
          </Row>
        </Container>
        <CookieConsent
          enableDeclineButton
          declineButtonText={currentLang.cookieDecline}
          setDeclineCookie
          buttonText={currentLang.cookieAccept}
          location="bottom"
          cookieName="vh-ga-gtm-tracking"
          style={{ background: "#e8dcd5", color: "#000" }}
          buttonStyle={{
            color: "#ffffff",
            background: "#484349",
            border: "1px solid transparent",
            borderRadius: "1rem",
            fontSize: "12px",
          }}
          declineButtonStyle={{
            color: "#000",
            background: "#e8dcd5",
            padding: "0",
            fontSize: "12px",
          }}
          expires={60}
        >
          <span style={{ fontSize: "12px", lineHeight: "12px" }}>
            {currentLang.cookieText.p}<a href={currentLang.privacy.link}>{currentLang.cookieText.here}</a>.
          </span>
        </CookieConsent>
      </Container>
    )
}

export default Footer